(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/templates/base/assets/javascripts/base.js') >= 0) return;  svs.modules.push('/templates/base/assets/javascripts/base.js');


var svs = svs || {};

(function(svs) {
  'use strict';

  if (svs.core.detect.os.iOS() === true) {
    svs.core.detect.orientation.on('change', function() {
      $(window).scrollTop(0);
    });
  }

  if (svs.core.detect.formfactor.desktop()) {
    setFocusOnTab();
  }


  function setFocusOnTab() {
    var lastKey = new Date(),
      lastClick = new Date(),
      focusClass = 'svs-keyboard-focus',
      focusSelector = '.svs-keyboard-focus',
      $document = $(document);

    $document.on('focusin', function(e) {
      $(focusSelector).removeClass(focusClass);
      var wasByKeyboard = lastClick < lastKey;

      if (wasByKeyboard) {
        $(e.target).addClass(focusClass);
      }
    });

    $document.on('mousedown', function() {
      lastClick = new Date();
    });

    $document.on('keydown', function() {
      lastKey = new Date();
    });
  }

  if (svs.core.detect.feature('ft-smarter-header') && svs.core.detect.formfactor.desktop()) {
    document.addEventListener('DOMContentLoaded', function() {
      if(
        svs.components &&
        svs.components.header &&
        svs.components.header.setShrinkableHeader
      ) {
        svs.components.header.setShrinkableHeader();
      }
    });
  }

})(svs);


 })(window);